var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "w-100 bg-primary position-fixed bg-resize-modal vw-100 vh-100 top-0 left-0 bottom-0 right-0 d-flex align-items-center justify-content-center",
      style: { "z-index": 9999 }
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "center-modal bg-white d-flex flex-column text-center" },
      [
        _c("div", { staticClass: "img-st d-flex justify-content-center" }, [
          _c("img", {
            staticClass: "img-loader",
            attrs: {
              src:
                "https://listen-images.s3.us-east-2.amazonaws.com/isotipo_isc_140x164.svg",
              alt: ""
            }
          })
        ]),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column align-items-center justify-content-center"
          },
          [
            _c("div", { staticClass: "text-center" }, [
              _c("span", { staticClass: "text-secondary" }, [
                _vm._v(
                  " For a better experience, we suggest to display this website on a screen bigger than "
                )
              ])
            ]),
            _c("div", { staticClass: "text-center" }, [
              _c("span", { staticClass: "text-secondary font-weight-bold" }, [
                _vm._v(" 1024px (width) ")
              ])
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }