module.exports = {
    tabsSideBar : [
        {
            name : 'overview',
            icon : 'fa fa-eye',
            label : 'Overview',
            open : false,
            subTabs : [
                {
                    icon : 'fa fa-star',
                    name : 'accounts',
                    label : 'Accounts',
                    routeName : '/overview/accounts',
                    levels : ['overview','accounts']
                },
                {
                    icon : 'fa fa-table',
                    name : 'channels',
                    label : 'Channels',
                    routeName : '/overview/channels',
                    levels : ['overview','channels']
                }
            ]
        },
        {
            name : 'campaigns',
            icon : 'fa fa-bullhorn',
            label : 'Campaigns',
            open : false,
            subTabs : [
                {
                    name : 'campaigns-all',
                    label : 'All',
                    routeName : '/campaigns/all',
                    levels : ['campaigns','all']
                },
                {
                    name : 'general-overview',
                    label : 'General Overview',
                    routeName : '/campaigns/general-overview',
                    levels : ['campaigns','general overview']
                }
            ]
        },
        {
            name : 'market_trends',
            icon : 'fas fa-chart-line',
            label : 'Market Trends',
            open : false,
            subTabs : [
                {
                    icon : 'fa fa-star',
                    name : 'industry',
                    label : 'By Industry',
                    routeName : '/market/industry',
                    levels : ['market','by industry']
                },
                /* {
                    icon : 'fa fa-search',
                    name : 'search',
                    label : 'Search',
                    routeName : '/market/search',
                    levels : ['market','search']
                } */
            ]
        },
        {
            name : 'benchmark',
            icon : 'fas fa-balance-scale',
            label : 'Benchmark',
            open : false,
            subTabs : [
                {
                    icon : 'fab fa-facebook',
                    name : 'facebook',
                    label : 'Facebook',
                    routeName : '/benchmark/facebook',
                    levels : ['benchmark','facebook']
                },
                {
                    icon : 'fab fa-youtube',
                    name : 'youtube',
                    label : 'Youtube',
                    routeName : '/benchmark/youtube',
                    levels : ['benchmark','youtube']
                },
                /* {
                    icon : 'fab fa-twitter',
                    name : 'twitter',
                    label : 'Twitter',
                    routeName : '/benchmark/twitter',
                    levels : ['benchmark','twitter']
                }, */
                {
                    icon : 'fab fa-instagram',
                    name : 'instagram',
                    label : 'Instagram',
                    routeName : '/benchmark/instagram',
                    levels : ['benchmark','instagram']
                },
                {
                    icon : 'fab fa-linkedin',
                    name : 'linkedin',
                    label : 'Linkedin',
                    routeName : '/benchmark/linkedin',
                    levels : ['benchmark','linkedin']
                }
            ]
        }
    ]
}