var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebarLateral " },
    [
      _vm.isScreensizeLess ? _c("screensize-modal") : _vm._e(),
      _c("div", [
        _vm.boolLogin
          ? _c("aside", { staticClass: "loginClassOfSession" })
          : _vm._e(),
        _vm.boolHome
          ? _c(
              "aside",
              {
                staticClass: "sidebar-nav bg-secondary ",
                staticStyle: { position: "absolute", height: "60px" }
              },
              [_vm._m(0)]
            )
          : _vm._e(),
        _vm.boolSettings && !_vm.boolLogin
          ? _c(
              "aside",
              {
                staticClass: "sidebar-nav bg-secondary",
                staticStyle: { height: "60px" }
              },
              [_vm._m(1)]
            )
          : _vm._e(),
        !_vm.boolHome && !_vm.boolSettings && !_vm.boolLogin
          ? _c("aside", { staticClass: "sidebar-nav bg-secondary" }, [
              _vm._m(2),
              _c(
                "nav",
                {
                  directives: [
                    {
                      name: "custom-click-outside",
                      rawName: "v-custom-click-outside",
                      value: _vm.hideMenu,
                      expression: "hideMenu"
                    }
                  ]
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-unstyled sidebar-nav-st" },
                    _vm._l(_vm.tabs, function(tab, index) {
                      return _c(
                        "li",
                        {
                          key: index + tab.label,
                          staticClass:
                            "text-center cursor-pointer position-relative",
                          class: [
                            _vm.navItemActive === tab.name ? "item_active" : ""
                          ],
                          on: {
                            click: function($event) {
                              return _vm.toggleSubList(index)
                            }
                          }
                        },
                        [
                          _c("i", { class: [tab.icon, "d-block"] }),
                          _c("span", [_vm._v(_vm._s(tab.label))]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: tab.open,
                                  expression: "tab.open"
                                }
                              ],
                              class: ["subList"]
                            },
                            _vm._l(tab.subTabs, function(subtab, key) {
                              return _c(
                                "div",
                                {
                                  key: subtab + key,
                                  staticClass: "text-left d-block w-100",
                                  on: {
                                    click: function($event) {
                                      return _vm.navigateTo(subtab, tab)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    class: [subtab.icon, "icon-sublist"]
                                  }),
                                  _vm._v(" " + _vm._s(subtab.label) + " ")
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "toplogo d-flex justify-content-center" }, [
      _c("span", { staticClass: "xn-logo" }, [
        _c("a", { attrs: { href: "https://www.isocialcube.com/" } }, [
          _c("img", {
            attrs: {
              src: require("../assets/icons/logo.png"),
              alt: "Isocialcube"
            }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "toplogo d-flex justify-content-center" }, [
      _c("span", { staticClass: "xn-logo d-block" }, [
        _c("a", { attrs: { href: "/overview/accounts" } }, [
          _c("img", {
            attrs: {
              src: require("../assets/icons/logo.png"),
              alt: "Isocialcube"
            }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "toplogo d-flex justify-content-center" }, [
      _c("span", { staticClass: "xn-logo d-block" }, [
        _c("a", { attrs: { href: "/overview/accounts" } }, [
          _c("img", {
            attrs: {
              src: require("../assets/icons/logo.png"),
              alt: "Isocialcube"
            }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }