import Vuex from 'vuex'
import Vue from 'vue'
import uiState from './uiState'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    uiState
  }
})
