(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("vue-router"), require("vuex"));
	else if(typeof define === 'function' && define.amd)
		define(["vue", "vue-router", "vuex"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("vue"), require("vue-router"), require("vuex")) : factory(root["vue"], root["vue-router"], root["vuex"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE_vue__, __WEBPACK_EXTERNAL_MODULE_vue_router__, __WEBPACK_EXTERNAL_MODULE_vuex__) {
return 