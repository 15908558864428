module.exports = {
    namespaced: true,
    state: {
        navItemActive : ((window.location.pathname).split('/'))[1] || 'overview',
        levelsActive : ['overview','accounts']
    },
    mutations: {
        setNavItemActive  (state, value)
        {            
            state.navItemActive = value
        },
        setLevelsActive  (state, value)  
        {           
            state.levelsActive = value
        }
    }
}