import Router from "vue-router";

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      beforeEnter: (to, from, next) => {
        let isLogged = false
        //verificar token si existe y funciona llamar a la función next
        let rtoken=sessionStorage.getItem("refreshToken");
        let token=sessionStorage.getItem("token");
        if(rtoken!=null && token != null) isLogged=true
        
        if(isLogged){
          next('overview/accounts')
        }
        else{
          next('/login')
        }
      }
    },
  ],
});
