<template>
  <div>
    <side-bar />
    <router-view />
  </div>
</template>

<script>
import SideBar from "./components/SideBar.vue";
export default {
  name: "Navigation",
  components: {
    SideBar,
  },
};
</script>
