<template>
  <div class="sidebarLateral ">
    <screensize-modal v-if="isScreensizeLess"/>
    <div >
      <aside class="loginClassOfSession" style="" v-if="boolLogin"></aside>
      <aside class="sidebar-nav bg-secondary " style=" position: absolute;height:60px;" v-if="boolHome">
      <div class="toplogo d-flex justify-content-center">
          <span class="xn-logo">
            <a href="https://www.isocialcube.com/"><img src="../assets/icons/logo.png" alt="Isocialcube"></a>
          </span>
        </div>
      </aside>
      <aside class="sidebar-nav bg-secondary" style="height:60px;" v-if="boolSettings && !boolLogin">
      <div class="toplogo d-flex justify-content-center">
          <span class="xn-logo d-block">
            <a href="/overview/accounts"><img src="../assets/icons/logo.png" alt="Isocialcube"></a>
          </span>
        </div>
      </aside>
      <aside class="sidebar-nav bg-secondary" v-if="!boolHome && !boolSettings && !boolLogin">
        <div class="toplogo d-flex justify-content-center">
          <span class="xn-logo d-block">
            <a href="/overview/accounts">
              <img src="../assets/icons/logo.png" alt="Isocialcube">
            </a>
          </span>
        </div>

        <nav v-custom-click-outside="hideMenu">
          <ul class="list-unstyled sidebar-nav-st">
            <li
              v-for="(tab, index) in tabs"
              :class="[navItemActive === tab.name ? 'item_active' : '']"
              class="text-center cursor-pointer position-relative"
              :key="index + tab.label "
              @click="toggleSubList(index)"
            >
              <i :class="[tab.icon, 'd-block']"></i>
              <span>{{ tab.label }}</span>

              <div :class="['subList']" v-show="tab.open">
                <div
                  class="text-left d-block w-100"
                  v-for="subtab,key in tab.subTabs"
                  @click="navigateTo(subtab, tab)"
                  :key="subtab + key"
                >
                  <i :class="[subtab.icon, 'icon-sublist']"></i>
                  {{ subtab.label }}
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </aside>
    </div>
  </div>
</template>


<script>
import { mapState, mapMutations } from "vuex";
import { tabsSideBar } from "@/constants/menus";
import ScreensizeModal from "./ScreensizeModal.vue";
import axios from "axios"

export default {
  name: "SideBar",
  components:{
    ScreensizeModal
  },
  computed: {
    ...mapState("uiState", ["navItemActive"]),
  },
  data() {
    return {
      tabs: tabsSideBar,
      boolHome: false,
      boolSettings: false,
      boolLogin: false,
      width: document.documentElement.clientWidth,
      isScreensizeLess: false,
    };
  },
  async created(){
    await this.getDrivers()
  },
  mounted() {    
    const pathUrl = window.location.pathname
    if(pathUrl=="/campaign"){
      this.setNavItemActive("campaigns")
    }
    
    if(pathUrl === '/settings/home' || pathUrl === '/settings/quickstar'){
      this.boolHome = true
      this.boolLogin = true
    }else if(pathUrl === '/settings/settings'){
        this.boolHome = true
    }
    else{      
      let arrSplitPath = pathUrl.split('/')
      if(arrSplitPath.includes('settings')){
        this.boolSettings = true
      }
      if(arrSplitPath.includes('login')){
        this.boolLogin = true
      }
    }
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);    
  },
  methods: {
    ...mapMutations("uiState", ["setNavItemActive", "setLevelsActive"]),
    toggleSubList(indexOfTab) {
      //funcion que busca un tab cerrado y lo muestra (si está abierto lo cierra)
      this.tabs.forEach(
        (tab, index) => (tab.open = index === indexOfTab && !tab.open)
      );
    },  
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      (this.width < 1024) ? this.isScreensizeLess = true : this.isScreensizeLess = false;
    },  
    async getDrivers(){  
      try {
        let drivers=localStorage.getItem("Drivers") 
        const _TOKEN = sessionStorage.getItem('token')
        const _REFRESH_TOKEN = sessionStorage.getItem('refreshToken')
        const _IP_PUBLIC = localStorage.getItem('ip-public')
        const _IP_LOCAL = localStorage.getItem('ip-local')
        const fulltoken = _TOKEN+'|'+_REFRESH_TOKEN+'|'+_IP_PUBLIC+'|'+_IP_LOCAL
          
        if(drivers==null){       
          const query = {
            query: `query geoDrivers($social_network: String){
              geoDrivers(social_network: $social_network){
                items
                count
              }
            }`,
            variables:{
              "social_network": "fb"
            }
          }
          const response= await axios.post(process.env.VUE_APP_API_GRAPHGQL_LISTENING,query,{
            headers:{
                'Content-Type': 'application/json',
                'api_key':'a6e71e02cd9c6565c2ad2fbaf135bf64',
                'authorization':fulltoken
            }})

          localStorage.setItem("Drivers",JSON.stringify(response.data.data.geoDrivers.items.data) )
        }
                  
      } catch (error) {
      }
     
    },
    navigateTo(subtab, tab) {
  
      this.setLevelsActive(subtab.levels);
      this.setNavItemActive(tab.name);
   
      this.$router.replace({ path: subtab.routeName}).catch((_) => {});
    },
    hideMenu() {
      const tab = this.tabs.find((tab) => tab.open);
      if (tab) {
        tab.open = false;
      }
    },
  },
};
</script>


<style scoped>
.loginClassOfSession{
  height:0px !important;
  width:0px !important;
  margin: 0px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.sidebar-nav {
  width: 70px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.toplogo {
  width: 70px;
  height: 60px;
}

.toplogo span {
  padding-top: 12px !important;
}

.xn-logo {
  height: 60px !important;
}

.xn-logo a img {
    width: 35px;
    height: 40px;
    position: relative;
    left: -3px;
    top: -3px;
}

.toplogo span a {
  width: 37px !important;
  height: 37px !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/*-------ICONS-------*/
li {
  line-height: 1;
  color: var(--skygray);
  height: 90px;
  padding-top: 20px;
  font-size: 18px;
  border-right: 3px solid transparent;
  transition: all ease 0.3s;
}

.sidebar-nav-st span {
  font-size: 9.6px;
  line-height: 14px;
}
.sidebar-nav-st i {
  font-size: 1.125rem;
  line-height: 1rem;
}

i {
  margin: 3px 0px 1px 0px;
}

li:hover {
  border-color: var(--primary);
  background-color: var(--semidark);
}

.item_active {
  color: var(--primary);
  background-color: var(--semidark);
}

.subList {
  border-radius: 0.25rem;
  top: 0;
  background-color: var(--secondary);
  position: absolute;
  left: 80px;
  width: 220px;
}

.subList > div {
  height: 45px;
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
  padding: 12px 10px;
  border-color: #23303b;
  color: white;
  transition: all ease 0.2s;
}

.subList > div:hover {
  border-right-style: solid;
  border-right-width: 6px;
  border-color: var(--primary);
  color: var(--primary);
  padding-left: 15px;
}

.subList::after {
  position: absolute;
  width: 0;
  height: 0;
  right: 100%;
  pointer-events: none;
  top: 23px;
  content: " ";
  background-color: rgba(0, 0, 0, 0) !important;
  border: solid transparent;
  border-right-color: #33414e;
  border-width: 5px;
  margin-top: -5px;
}

.icon-sublist {
  display: inline-block;
  margin-right: 10px !important;
}
</style>